.home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    color: #333;
    font-family: Arial, sans-serif;
    padding: 20px;
  }
  
  .hero-section {
    padding: 50px 20px;
    text-align: center;
    background-color: #00a76f;
    color: white;
    width: 100%;
  }
  
  .hero-content {
    max-width: 800px;
    margin: 0 auto;
  }
  
  .hero-title {
    font-size: 3em;
    margin-bottom: 20px;
  }
  
  .hero-subtitle {
    font-size: 1.5em;
    margin-bottom: 30px;
  }
  
  .cta-button {
    background-color: white;
    color: #00a76f;
    padding: 15px 30px;
    border: none;
    border-radius: 5px;
    font-size: 1em;
    cursor: pointer;
  }
  
  .features, .testimonials, .cta-section {
    padding: 50px 20px;
    text-align: center;
    width: 100%;
  }
  
  .section-title {
    font-size: 2.5em;
    margin-bottom: 40px;
  }
  
  .features-grid, .testimonial-grid {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
  }
  
  .feature-item, .testimonial-item {
    max-width: 300px;
    margin: 20px;
    text-align: center;
  }
  
  .feature-icon {
    width: 50px;
    height: 50px;
    margin-bottom: 20px;
  }
  
  .feature-title, .testimonial-author {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .feature-description, .testimonial-text {
    font-size: 1em;
  }
  
  .cta-title {
    font-size: 2em;
    margin-bottom: 20px;
  }
  