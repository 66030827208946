body {
    margin: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #f8f8ff; /* GhostWhite */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  